<template>
  <div class="grid grid-cols-12">
    <div class="col-start-0 md:col-start-3 lg:col-start-5 col-span-12 md:col-span-8 lg:col-span-4">
      <div class="mb-3 w-full">
        <van-progress :percentage="getPercentage" :show-pivot="false"
                      :style="{ width: 'calc(100% - 80px)' }"
                      class="absolute top-[12px] ltr:left-[40px] rtl:right-[40px]"
                      color="#222" pivot-color="#7232dd"
                      pivot-text="Purple"
                      stroke-width="4"/>
        <div class="flex items-center justify-between mx-3">
          <div v-for="(item, index) in steps" :key="`step-${index}`"
               class="flex flex-col items-center justify-between z-1">
            <div v-if="activeStep > index"
                 class="flex justify-center items-center w-[20px] h-[20px] bg-black text-white rounded-xl">
              <van-icon class="badge-icon" name="success" size="12"/>
            </div>
            <div v-else :style="{ 'background': activeStep >= index ? '#000' : '#ebedf0' }"
                 class="flex justify-center items-center w-[20px] h-[20px] text-white rounded-xl">
              <span class="text-xs font-weight-bold">{{ item.counter }}</span>
            </div>
            <div class="text-xs">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const {t} = useI18n()
const ROUTES = {
  ADDRESS_LIST: "checkout-address-list",
  ADDRESS: "checkout-address",
  EDIT_ADDRESS: "checkout-address-id",
  CHECKOUT: "checkout",
  SUCCESS: "success-id"
}

const steps = ref([
  {
    name: t('checkout.steps.shipping_address'),
    counter: 1
  },
  {
    name: t('checkout.steps.payment'),
    counter: 2
  },
  {
    name: t('checkout.steps.confirmed'),
    counter: 3
  }
])

const route = useRoute()
const routeName = computed(() => route.name.split('_')[0])

const routeValueMapping = {
  [ROUTES.ADDRESS_LIST]: 0,
  [ROUTES.ADDRESS]: 0,
  [ROUTES.EDIT_ADDRESS]: 0,
  [ROUTES.CHECKOUT]: 1,
  [ROUTES.SUCCESS]: 2,
};

const activeStep = computed(() => routeValueMapping[routeName.value])

const getPercentage = computed(() => Number(activeStep.value) * 50)
</script>
